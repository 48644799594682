import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from 'ckeditor5-custom-build'

import './CustomEditor.css'

function CustomEditor({ setState , data }) {
    return (
        <div className="custom_editor">
            <CKEditor
            editor={ClassicEditor}
            data={data}
            onChange={(event, editor) => {
                setState(editor.getData())
           
            }}
            // onChange={(event, editor) => setState(editor.getData())}
            // onReady={editor => console.log(editor)}
        />
        </div>
    )
}

export default CustomEditor