import { RiCalendar2Fill, RiMapPin2Fill, RiUserHeartFill } from "react-icons/ri"
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard"
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList"

function EmployeesScreen() {
  return (
    <NavCardList numOfCards={'two'}>
        <NavCard cardName={'Employeees'} navCardLink='/employees' ><RiMapPin2Fill /></NavCard>
        <NavCard cardName={'Employee Invites'} navCardLink='/employeeInvites' ><RiMapPin2Fill /></NavCard>
        <NavCard cardName={'Designations'} navCardLink='/designations' ><RiUserHeartFill /></NavCard>
        <NavCard cardName={'Attendance'} navCardLink='/attendanceScreens' ><RiCalendar2Fill /></NavCard>
    </NavCardList>
  )
}

export default EmployeesScreen