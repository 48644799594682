
import { APP_SETTINGS_API } from "../../../Utilities/APIs";
import BINCRUD from "../../Partials/Layouts/CRUDs/CRUDLayouts/BINCRUD/BINCRUD";

function AppSettings() {
  return (
    <BINCRUD
      // common props
      api={APP_SETTINGS_API}
      screenTopicSingular='App Setting'
      screenTopicPlural='App Settings'


      // crud header props
      showTotalInHeading


      // crud props
      allowCreate
      allowUpdate
      allowDelete


      // field props
      booleanDBField={'isCategoryFeatured'}
      booleanName={'Is Category Featured?'}
    />
  )
}

export default AppSettings